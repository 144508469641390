import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

// 屏蔽双击路由链接报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const Welcome = () => import ('../views/Welcome');
const Performances = () => import ('../views/Performances');
const Register = () => import ('../views/Register');
const Login = () => import ('../views/Login');
const Profile = () => import ('../views/Profile');
const Invites = () => import ('../views/Invites');
const Wallet = () => import ('../views/Wallet');
const Profit = () => import ('../views/Profit');
const Withdrawl = () => import ('../views/Withdrawl');
const Tasks = () => import ('../views/Tasks');
const Signin = () => import ('../views/Signin');
const Tinjiku = () => import ('../views/Tinjiku');
const Share = () => import ('../views/Share');
const Article = () => import ('../views/Article');
const Security = () => import ('../views/Security');
const Manage = () => import ('../views/Manage');
const Swap = () => import ('@/views/Swap');
const Home = () => import ('../views/Home');
const Gongxiu = () => import ('../views/Gongxiu');
const Budhi = () => import ('../views/Budhi');
const Stake = () => import ('../views/Stake');


const routes = [{
    path: '/home',
    name: 'home',
    component: Home,
}, {
    path: '/',
    name: 'welcome',
    component: Welcome,
    meta:{
        title: 'Tenjiku'
    }
}, {
    path: '/user',
    name: 'user',
    component: Profile
},{
    path: '/register/:i?',
    name: 'register',
    component: Register,
}, {
    path: '/login',
    name: 'login',
    component: Login,
}, {
    path: '/share',
    name: 'share',
    component: Share,
}, {
    path: '/swap',
    name: 'swap',
    component: Swap,
}, {
    path: '/article/:id?',
    name: 'article',
    component: Article,
}, {
    path: '/security',
    name: 'security',
    component: Security
}, {
    path: '/wallet',
    name: 'wallet',
    component: Wallet,
}, {
    path: '/profits/:mode?',
    name: 'profits',
    component: Profit,
}, 
{
    path: '/manage',
    name: 'manage',
    component: Manage
}, 
{
    path: '/withdrawls',
    name: 'withdrawls',
    component: Withdrawl,
}, {
    path: '/stake',
    name: 'stake',
    component: Stake
}, {
    path: '/tasks',
    name: 'tasks',
    component: Tasks
}, {
    path: '/budhi',
    name: 'budhi',
    component: Budhi
}, 
{
    path: '/signin',
    name: 'signin',
    component: Signin
}, {
    path: '/tinjiku',
    name: 'tinjiku',
    component: Tinjiku
}, {
    path: '/invites/:paddress?',
    name: 'invites',
    component: Invites
}, {
    path: '/performances',
    name: 'performances',
    component: Performances
}, {
    path: '/gongxiu',
    name: 'gongxiu',
    component: Gongxiu
},
];

const router = new VueRouter({
    mode: "hash",
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    let token = store.state.token;
    let noNeedLoginRoutes = ['login','register','home','welcome','tasks','signin'];
    if (!token && noNeedLoginRoutes.indexOf(to.name)<0) {
        return next({ name: 'login' });
    }
    if (token && (to.name == 'login' || to.name=='register')){
        return next({ name: 'home'});
    }
    next();
  })
export default router;